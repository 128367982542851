<template>
  <div>
    <my-header
      btnRight="mdi-arrow-right"
      :title="'404'"
      @clickBtnRight="goBack"
      class="lightPurple"
    />
    <div class="d-flex flex-column center-all pa-16 ma-16">
      <v-img :src="`/imgs/admin/login.png`" />
      <h1>הדף לא נמצא</h1>
      <h1>{{ $props.query }}</h1>
    </div>
  </div>
</template>

<script>
import MyHeader from "../components/MyHeader.vue";
export default {
  components: { MyHeader },
  name: "NotFound",
  props: ["query"],
  methods: {
    goBack() {
      this.$store.commit("action/unset");
      this.$router.go(-2);
    },
  },
};
</script>

<style>
</style>